<template>
  <div>
    <public-header />

    <router-view />

    <public-footer />
  </div>
</template>

<script>
import PublicHeader from "@/components/cms/PublicHeader.vue";
import PublicFooter from "@/components/cms/PublicFooter.vue";

export default {
  components: {
    PublicHeader,
    PublicFooter,
  },

  mounted() {
    if(! this.$store.getters.getAuthUser.admin) {
      this.$router.push({ name: 'dashboard' })
    }
  },
};
</script>
