<template>
  <div class="site-content">
    <div class="home-slider">
      <div class="slide">
        <div class="image">
          <img src="/theme/home-main.png" alt="" />
        </div>
        <div class="caption">
          <div class="content">
            <h4 class="title">
              Reduce your carbon footprint, invest in verified sustainable
              projects
            </h4>
            <h5 class="subtitle">Join us on the journey to net-zero</h5>
            <p class="actions mt-4">
              <router-link to="" class="btn bg-primary text-white me-3"
                >Get Started</router-link
              >
              <router-link to="" class="btn bg-white">How It Works</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="home-highlights">
      <div class="container">
        <div class="row">
          <div class="col-md-4" v-for="i in 3" :key="`highlight-${i}`">
            <div class="card shadow">
              <div class="card-body">
                <div class="icon">
                  <img src="/theme/bitcoin icon.svg" alt="icon" />
                </div>
                <div class="content">
                  <h4 class="mb-4 mobile-only">
                    <img
                      src="/theme/bitcoin icon.svg"
                      alt="icon"
                      class="me-2"
                    />
                    Measure
                  </h4>
                  <h4 class="mb-4 desktop-only">Measure</h4>
                  <p>
                    Use our carbon footprint calculators for individuals, small
                    businesses, charities and institutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="mobile-only" />

    <div class="how-it-works">
      <div class="container">
        <h5 class="site-subtitle text-center">Our Process</h5>
        <h4 class="site-title text-center mb-3 mb-md-5">How It Works</h4>
        <div class="row pb-0 pb-md-5">
          <div class="col-md-3 text-center" v-for="i in 4" :key="`how-${i}`">
            <h6 class="site-subtitle">Step {{ i }}</h6>
            <h5>Measure</h5>
            <img src="/theme/calculator.svg" alt="icon" class="my-md-4 my-0" />
            <p>
              Quantify carbon footprint, assess emissions from activities,
              establish baseline for sustainable improvements.
            </p>
          </div>
        </div>
        <div class="row mt-5 pb-2 pb-md-5">
          <div class="col-6 col-md-3 mb-4" v-for="i in 8" :key="`brand-${i}`">
            <img src="/theme/brand.png" alt="brand" class="w-100" />
          </div>
        </div>
      </div>
    </div>

    <div class="featured-projects pb-2 pb-md-5">
      <div class="container">
        <h5 class="site-subtitle text-center">Leading Green Investments</h5>
        <h4 class="site-title text-center mb-2 mb-md-5">Featured Projects</h4>
        <div class="row">
          <div class="col-md-3" v-for="i in 4" :key="`featured-${i}`">
            <div class="card mb-2">
              <span class="category">Hydrogen Energy</span>
              <img
                src="/theme/featured.png"
                alt="featured"
                class="card-img-top"
              />
              <div class="card-body">
                <div class="text-muted text-sm">
                  <i class="fa fa-map-marker me-2"></i>South Africa
                </div>
                <h6 class="my-2">Africa Green Hydrogen Alliance</h6>
                <p>
                  South Africa has the competitive advantage to produce and
                  export green hydrogen energy and aims to work on the existing
                  opportunities to ...
                </p>
                <router-link to="" class="text-primary">Read More</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
