<template>
  <div class="container-fluid">
    <header class="d-flex flex-wrap py-md-2 border-bottom">
      <div class="col-md-2 desktop-only">
        <a href="/" class="d-inline-flex site-logo">
          <img src="/logo.svg" alt="logo" />
        </a>
      </div>

      <div class="col-md-12 mobile-only">
        <div class="row">
          <div class="col-md-12 d-flex justify-content-between bg-primary mobile-top-nav">
            <div class="dropdown d-inline-block me-2 language-selector no-arrow">
              <a
                class="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                href="#"
              >
                <img src="/theme/flag.svg" alt="flag" /> <span class="text-white">En</span>
            </a>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item text-dark" href="#"
                    ><img src="/theme/flag.svg" alt="flag" /> English</a
                  >
                </li>
              </ul>
            </div>
            <div class="dropdown d-inline-block me-2 theme-selector me-3">
              <a
                class="btn btn-link btn-sm dropdown-toggle p-0"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="/assets/dark-mode.png"
                  alt="dark-mode"
                  style="height: 29px"
                />
            </a>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item text-dark" href="#"
                    ><i class="fa fa-sun-o me-2"></i> Light</a
                  >
                </li>
              </ul>
            </div>
            <router-link
              :to="{ name: 'cms.login' }"
              class="me-2"
              ><i class="fa fa-lock me-2"></i> Log In</router-link
            >
            <router-link
              :to="{ name: 'cms.register' }"
              class="btn-sm"
            >
              <i class="fa fa-user me-2"></i> Sign Up
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-2 centered">
            <a href="#" @click.prevent="toggleMainMenu"><i class="fa fa-bars" style="font-size: 20px"></i></a>
          </div>
          <div class="col-10">
            <a href="/" class="d-inline-flex site-logo" style="height: 60px;">
              <img src="/logo2.svg" alt="logo" />
            </a>
          </div>
        </div>
      </div>

      <div class="col-md-10">
        <div class="row">
          <div class="col-md-12 text-end border-bottom pb-2 desktop-only">
            <div class="dropdown d-inline-block me-2 language-selector">
              <button
                class="btn btn-link btn-sm dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src="/theme/flag.svg" alt="flag" /> En
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="#"
                    ><img src="/theme/flag.svg" alt="flag" /> English</a
                  >
                </li>
              </ul>
            </div>
            <div class="dropdown d-inline-block me-2 theme-selector me-3">
              <button
                class="btn btn-link btn-sm dropdown-toggle p-0"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="/assets/dark-mode.png"
                  alt="dark-mode"
                  style="height: 29px"
                />
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="#"
                    ><i class="fa fa-sun-o me-2"></i> Light</a
                  >
                </li>
              </ul>
            </div>
            <router-link
              :to="{ name: 'cms.login' }"
              class="btn btn-primary btn-sm me-2"
              ><i class="fa fa-lock me-2"></i> Log In</router-link
            >
            <router-link
              :to="{ name: 'cms.register' }"
              class="btn btn-outline-primary btn-sm"
            >
              <i class="fa fa-user me-2"></i> Sign Up
            </router-link>
          </div>
          <div id="site-main-menu" class="mobile-hide">
            <ul
              class="nav col-12 col-md-12 mb-2 justify-content-center mb-md-0 site-menu mt-2"
              id="site-menu"
            >
              <li class="menu-logo mobile-only">
                <a href="#" class="mobile-close-main-menu p-3" @click.prevent="toggleMainMenu">X</a>
                <router-link :to="{ name: 'cms' }">
                  <img src="/logo.svg" alt="logo" class="p-3">
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'cms' }"
                  :class="`nav-link px-2 ${
                    $route.name == 'cms' ? 'active' : ''
                  }`"
                  ><i class="fa fa-home me-2"></i> Home</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'cms.our-projects' }"
                  :class="`nav-link px-2 ${
                    $route.name == 'cms.our-projects' ||
                    $route.name == 'cms.our-projects.show'
                      ? 'active'
                      : ''
                  }`"
                  ><i class="fa fa-list me-2"></i> Our Projects</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'cms.our-services' }"
                  :class="`nav-link px-2 ${
                    $route.name == 'cms.our-services' ? 'active' : ''
                  }`"
                  ><i class="fa fa-file me-2"></i> Our Services</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'cms.carbon-calculator' }"
                  :class="`nav-link px-2 ${
                    $route.name == 'cms.carbon-calculator' ? 'active' : ''
                  }`"
                  ><i class="fa fa-calculator me-2"></i> Carbon
                  Calculator</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'cms.resources' }"
                  :class="`nav-link px-2 ${
                    $route.name == 'cms.resources' ? 'active' : ''
                  }`"
                  ><i class="fa fa-cog me-2"></i> Resources</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'cms.contact-us' }"
                  :class="`nav-link px-2 ${
                    $route.name == 'cms.contact-us' ? 'active' : ''
                  }`"
                  ><i class="fa fa-envelope me-2"></i> Contact Us</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  methods: {
    toggleMainMenu() {
      document.getElementById('site-main-menu').classList.toggle('mobile-hide')
    }
  }
};
</script>
